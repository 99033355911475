import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
// import styles
import './main.css'
import { auth } from './config/fb'
let app: any = ''
auth.onAuthStateChanged(() => {
    if (!app) {
        app = createApp(App).use(router).mount('#app')
    }
    else {
        app.$router.push('/')
    }
    })
