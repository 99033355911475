import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
// import getAuth from '../firebase'
import firebase from 'firebase/app'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/services',
    name: 'services',
    component: () => import(/* webpackChunkName: "services" */ '../views/ServicesView.vue'),
    meta: {
      authRequired: true,
    },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  const auth = getAuth();
  if (to.matched.some(record => record.meta.authRequired)) {
    if (auth.currentUser) {
      console.log('auth.currentUser', auth.currentUser);
      next();
    } else {
      alert('You must be logged in to see this page');
      next({
        path: '/',
      });
    }
  } else {
    next();
  }
});

export default router
