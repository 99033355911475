
import { defineComponent } from "vue";
import {
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
  browserSessionPersistence,
  sendPasswordResetEmail,
} from "firebase/auth";
import { stringifyQuery } from "vue-router";

export default defineComponent({
  name: "HomeView",
  data() {
    return {
      email: "",
      password: "",
      passwordReset: false,
      userLoggedIn: false,
      displayName: "",
    };
  },
  methods: {
    login() {
      const auth = getAuth();
      setPersistence(auth, browserSessionPersistence)
        .then(() => {
          console.log("set persistence");
          return signInWithEmailAndPassword(auth, this.email, this.password);
        })
        .then(()=> {
          this.userLoggedIn = true;
          this.$router.push("/services");
        })
        .catch((error: { code: any; message: any }) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode, errorMessage);
        });
    },
    forgotPassword() {
      const auth = getAuth();
      const emailAddress = this.email;
      if (!emailAddress || emailAddress == "") {
        return;
      }
      sendPasswordResetEmail(auth, this.email)
        .then(() => {
          // Password reset email sent!
          // ..
        })
        .catch((error: { code: any; message: any }) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          // ..
        });
    },
  },
  mounted() {
    // check if user is logged in
    const auth = getAuth();
    const user = auth.currentUser;
    if (user != null) {
      this.$router.push("/services");
    }
  },
});
